<template>
  <b-modal size="lg" v-model="show" title="How To Topup" hide-footer @hide="onHide" body-class="p-0">
    <div id="formerchant" class="container mt-2">
      <div class="menu-topup d-flex justify-content-end mb-3">
        <div class="mr-3">Topup via ATM</div> <img width="20" src="https://www.mbayar.co.id/assets/img/web-icon-va.svg"
          class="icon-bank" alt="">
      </div>
      <el-collapse v-model="activeName.atm" accordion class="mb-3">
        <el-collapse-item v-for="(item, index) in via_atm" :key="index" title="Consistency" :name="index">
          <template slot="title">
            <img width="50" :src="item.bank_icon" class="ml-2" alt="">
          </template>
          <h4 class="ml-2">Instruksi</h4>
          <ol style="list-style: decimal;">
            <li v-for="(step, i) in item.step" :key="i">{{ step }}</li>
          </ol>
        </el-collapse-item>
      </el-collapse>

      <div class="menu-topup d-flex justify-content-end mb-3">
        <div class="mr-3"> Mobile Banking</div> <img width="20"
          src="https://www.mbayar.co.id/assets/img/Group-1489.svg" class="icon-bank" alt="">
      </div>
      <el-collapse v-model="activeName.mobile" accordion class="mb-3">
        <el-collapse-item v-for="(item, index) in via_mobile" :key="index" title="Consistency" :name="index">
          <template slot="title">
            <img width="50" :src="item.bank_icon" class="ml-2" alt="">
          </template>
          <h4 class="ml-2">Instruksi</h4>
          <ol style="list-style: decimal;">
            <li v-for="(step, i) in item.step" :key="i">{{ step }}</li>
          </ol>
        </el-collapse-item>
      </el-collapse>

      <div class="menu-topup d-flex justify-content-end mb-3">
        <div class="mr-3"> Internet Banking</div> <img width="20"
          src="https://www.mbayar.co.id/assets/img/web-icon-ebanking.svg" class="icon-bank" alt="">
      </div>
      <el-collapse v-model="activeName.ibanking" accordion class="mb-3">
        <el-collapse-item v-for="(item, index) in via_ibanking" :key="index" title="Consistency" :name="index">
          <template slot="title">
            <img width="50" :src="item.bank_icon" class="ml-2" alt="">
          </template>
          <h4 class="ml-2">Instruksi</h4>
          <ol style="list-style: decimal;">
            <li v-for="(step, i) in item.step" :key="i">{{ step }}</li>
          </ol>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="float-right p-2">
      <el-button @click="onHide" size="small" class="mr-2">Close</el-button>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'HowToTopup',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      phone_number: '',
      activeName: {
        atm: '',
        mobile: '',
        ibanking: '',
      },
    };
  },
  computed: {
    via_atm() {
      return [
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/logo_bca_rectangle.png',
          step: [
            'Pilih Menu Transfer ke Bank Lainnya',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/bank_mandiri.png',
          step: [
            'Pilih Menu Transfer ke Bank Lainnya',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/bni.png',
          step: [
            'Pilih Menu Transfer ke Bank Lainnya',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/bri.png',
          step: [
            'Pilih Menu Transfer ke Bank Lainnya',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/cimb2.png',
          step: [
            'Pilih Menu Transfer ke Bank Lainnya',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/permata.png',
          step: [
            'Pilih Menu Transfer ke Bank Lainnya',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/atm_bersama.jpg',
          step: [
            'Pilih Menu Transfer ke Bank Lainnya',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
      ];
    },
    via_mobile() {
      return [
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/logo_bca_rectangle.png',
          step: [
            'Pilih m-Transfer',
            'Pilih Daftar Transfer Antar Bank (Jika Anda belum mendaftarkan Rekening penerima)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor handphone Anda, contoh 7119 + ${this.phoneNumber}, pilih Bank CIMB Niaga lalu klik Send (Jika Anda belum mendaftarkan Rekening penerima)`,
            'Pilh Menu Transfer Antar Rekening',
            'Pilih Rekening Tujuan Anda, dan Masukkan Nominal Top Up lalu klik Send',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/bank_mandiri.png',
          step: [
            'Pilih Menu Transfer',
            'Pilih Ke Bank Lain Dalam Negeri',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/bni.png',
          step: [
            'Pilih Menu Transfer',
            'Pilih Transfer Online Antar Bank',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/bri.png',
          step: [
            'Pilih Menu Transfer',
            'Pilih Transfer ke Bank Lain',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/cimb2.png',
          step: [
            'Login ke akun Go Mobile Anda',
            'Pilih menu Top Up',
            'Pilih menu e-Wallet',
            'Pilih logo M-Bayar',
            `Masukkan Nomor nomor ponsel, contoh ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/permata.png',
          step: [
            'Pilih Menu Pembayaran',
            'Pilih Menu Pembayaran Tagihan dan Pilih Virtual Account',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/atm_bersama.jpg',
          step: [
            'Pilih Jenis Transaksi',
            'Pilih Menu Transfer',
            'Antar Bank/Transfer ke Bank Lain',
            'Pilih Bank Tujuan Transfer',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
      ];
    },
    via_ibanking() {
      return [
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/logo_bca_rectangle.png',
          step: [
            'Pilih Jenis Transaksi',
            'Pilih Transfer',
            'Antar Bank/Transfer ke Bank Lain',
            'Pilih Bank Tujuan Transfer',
            'Masukkan Kode Bank Tujuan (022-CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan Format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top up',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/bank_mandiri.png',
          step: [
            'Pilih Menu Transfer',
            'Pilih Transfer Antar Bank Online (Pastikan Anda menggunakan pilihan metode transfer ONLINE untuk Top-up Saldo anda. Jika Anda menggunakan metode transfer RKN/LLG atau RTGS, dana akan dikembalikan oleh bank melalui mekanisme pengembalian dana).',
            'Masukkan Kode Bank Tujuan CIMB Niaga',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/bni.png',
          step: [
            'Pilih Menu Transfer',
            'Pilih Transfer Online Antar Bank',
            'Masukkan Kode Bank Tujuan CIMB Niaga',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu klik Lanjutkan',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/bri.png',
          step: [
            'Pilih Menu Transfer Antar Bank',
            'Masukkan Kode Bank Tujuan (022 - CIMB Niaga)',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu klik Kirim',
          ],
        },
        {
          bank_icon: 'https://www.mbayar.co.id/uploads/1/2020-04/cimb2.png',
          step: [
            'Pilih Menu Pembayaran',
            'Pilih Menu Pembayaran Tagihan dan Pilih Virtual Account',
            `Masukkan Nomor Rekening Virtual Account Anda dengan format 7119 + Nomor Handphone Anda, contoh 7119 + ${this.phoneNumber}`,
            'Masukkan Nominal Top Up lalu Klik Submit',
          ],
        },
      ];
    },
  },
  methods: {
    onHide() {
      this.$emit('onHide');
    },
  },
  watch: {
    isShow() {
      this.show = this.isShow;
      this.phone_number = this.phoneNumber;
    },
  },
};
</script>