<template>
  <b-modal size="xl" v-model="show" title="Balance Histories" hide-footer @hide="onHide">
    <div>
      <el-date-picker
        v-model="date_range"
        @filter-change="loadHistories"
        type="daterange"
        size="small"
        class="mb-4 mr-2"
        style="width: 35%"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date">
      </el-date-picker>
      <el-input size="small" style="width: 35%" v-model="search_keyword" placeholder="Input Invoice Number eg: INV-20220809-00043-L6IQQ"></el-input>&nbsp;
      <el-select size="small" v-model="search_transaction_code">
        <el-option v-for="(item, i) in trx_code_options" :key="i" :value="item.value" :label="item.label"></el-option>
      </el-select>
      &nbsp;
      <el-button @click="page = 1; loadHistories()" size="mini" circle type="info"><font-awesome-icon icon="search"/></el-button>
      <el-button @click="page = 1; loadHistories('download')" size="mini" circle type="primary"><font-awesome-icon icon="download"/></el-button>
    </div>
    <el-skeleton :loading="loading.histories" :rows="6" animated />
    <el-table v-if="!loading.histories"
      class="w-100"
      :show-header="true"
      :data="histories"
      :row-class-name="trueBalance">
      <el-table-column
        prop="date_str"
        width="180"
        label="Date">
      </el-table-column>
      <el-table-column
        prop="title"
        min-width="250"
        label="Description">
      </el-table-column>
      <el-table-column
        prop="balanceBefore_str"
        min-width="100"
        label="Previous Balance">
      </el-table-column>
      <el-table-column
        prop="amount"
        label="Amount">
        <template slot-scope="item">
          <el-tag size="mini" effect="dark" type="success" v-if="item.row.type == 'C'">{{ item.row.amount_str }}</el-tag>
          <el-tag size="mini" effect="dark" type="danger" v-else-if="item.row.type == 'D'">-{{ item.row.amount_str }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="balance_str"
        min-width="100"
        label="Balance">
        <template slot-scope="item">
          <div class="flex items-center gap-2">
            {{ item.row.balance_str }}
            <el-tooltip class="item" effect="dark" content="tidak balance" placement="top">
              <span v-if="item.row.trueBalance === 0"><i class="el-icon-warning"></i></span>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <template slot="empty">
        <el-empty description="No data found" :image="emptyStateImage"></el-empty>
      </template>
    </el-table>
    <div class="card-footer d-flex justify-content-between">
      <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ row_count }} entries</small>
      <div class="inline-table">
        <!-- <b-pagination
          class="table-cell"
          @change="pageChangeHandler"
          :per-page="20"
          :limit="5"
          :total-rows="1000"
          v-model="page"
        /> -->
        <el-pagination @current-change="pageChangeHandler" v-model="page"></el-pagination>
      </div>
    </div>
    <!-- <table v-loading="loading.histories" class="table card-table border-1 w-100">
      <tr v-for="(item, index) in histories" :key="index" class="p-2">
        <td>{{ item.title }}</td>
        <td>
          <el-tag effect="dark" type="success" v-if="item.type == 'D'">{{ item.amount }}</el-tag>
          <el-tag effect="dark" type="danger" v-else-if="item.type == 'C'">-{{ item.amount }}</el-tag>
        </td>
      </tr>
    </table> -->
    <div class="d-flex float-right mt-2">
      <el-button @click="onHide" size="small" class="mr-2">Close</el-button>
    </div>
  </b-modal>
</template>
<style>
.el-table .warning-row {
  background: #fbf182;
}
</style>
<script>
import moment from 'moment';
import { orderBy } from 'lodash';
import {
  GET_WALLET_HISTORIES,
  DOWNLOAD_WALLET_HISTORIES,
} from '@/store/modules/companies';
import emptyStateImage from '@/assets/images/empty-state.png';
// import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'BalanceHistories',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    selectedCompany: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      show: false,
      emptyStateImage,
      loading: {
        histories: false,
      },
      package_list: [],
      form: {
        package_id: '',
      },
      benefit_list: [],
      showModalCreatePackage: false,
      date_range: [
        moment().format(),
        moment().endOf('day').format(),
      ],
      trx_code_options: [
        {
          value: '',
          label: 'All',
        },
        {
          value: '14',
          label: 'Cashout',
        },
      ],
      search_transaction_code: '',
      search_keyword: '',
      histories: [],
      row_count: 0,
      per_page: 20,
      page: 1
    };
  },
  computed: {
    showingFrom() {
      return this.row_count ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.row_count) {
        if (this.histories.length < this.per_page) {
          return this.row_count;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  methods: {
    trueBalance({row, rowIndex}) {
      if (row.trueBalance === 0) {
        return 'warning-row';
      }
      return '';
    },
    onHide() {
      this.search_keyword = '';
      this.search_transaction_code = '';
      this.date_range = [
        moment().format(),
        moment().endOf('day').format(),
      ];
      this.$emit('onHide');
    },
    async loadHistories(action = '') {
      this.loading.histories = true;
      await this.$store.dispatch(action === '' ? GET_WALLET_HISTORIES : DOWNLOAD_WALLET_HISTORIES, {
        id: this.selectedCompany.company_id,
        query: {
          start_date: moment(this.date_range[0]).startOf('day').format(),
          end_date: moment(this.date_range[1]).endOf('day').format(),
          page: this.page,
          clientRef: this.search_keyword,
          transactionCode: this.search_transaction_code,
        },
      }).then((response) => {
        if (action === 'download') {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Please check your email for downloaded file.',
          });
          return;
        }
        this.histories = response.histories.list.map((v) => {
          v.amount_str = `Rp${v.amount.toLocaleString()}`;
          v.balanceBefore_str = `Rp${v.balanceBefore.toLocaleString()}`;
          v.balance_str = `Rp${v.balance.toLocaleString()}`;
          v.date_str = moment(v.date).format('DD MMM YYYY, HH:mm');
          v.expectedSum = v.type == 'D' ? v.balanceBefore - v.amount : v.balanceBefore + v.amount;
          v.trueBalance = v.expectedSum == v.balance ? 1 : 0;
          return v;
        });
        this.histories = orderBy(this.histories, 'date', 'desc');
        this.row_count = response.histories.count;
      });
      this.loading.histories = false;
    },
    doShowModalCreatePackage() {
      this.showModalCreatePackage = true;
    },
    pageChangeHandler(page) {
      this.page = page;
      this.loadHistories();
    },
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      if (this.show) {
        this.page = 1;
        this.loadHistories();
      }
    },
  },
};
</script>
