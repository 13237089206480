import { render, staticRenderFns } from "./ActivateMbayar.vue?vue&type=template&id=470d2a4f"
import script from "./ActivateMbayar.vue?vue&type=script&lang=js"
export * from "./ActivateMbayar.vue?vue&type=script&lang=js"
import style0 from "./ActivateMbayar.vue?vue&type=style&index=0&id=470d2a4f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports