<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <app-user-analytic ref="user_analytic" :is-private="true"/>
    <my-benefit-company-analytic :analytic="myben_analytic"/>
    <div class="card col-lg-12">
      <div class="card-header justify-content-between">
        <div class="card-title">
          <span class="mr-2">{{ $route.name }} List</span>
        </div>
      </div>
      <div class="card-body">
        <div class="flex">
          <div class="w-1/3">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Filter Type">
                <el-select size="small" v-model="filter.search_by" class="mr-2 w-full">
                  <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Search Keyword">
                <el-input size="small" clearable @clear="searchHandler" type="text" v-model="filter.search_keyword" placeholder="Input search keyword" class="mr-2"/>
              </el-form-item>
            </el-form>
          </div>
          <div class="w-1/3">
            <el-form ref="form" label-width="140px">
              <el-form-item label="MyBenefit Package">
                <el-select @change="searchHandler" size="small" v-model="filter.mybenefit_package" class="mr-2 w-full">
                  <el-option value="" label="All"/>
                  <el-option v-for="(item, index) in package_list" :key="index" :value="item.id.toString()" :label="item.package_name"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Status">
                <el-select @change="searchHandler" size="small" v-model="filter.status_filter" class="mr-2 w-full">
                  <el-option v-for="(item, index) in status_options" :key="index" :value="item.value" :label="item.text"/>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="w-1/3">
            <el-form ref="form" label-width="140px">
              <el-form-item label="MyBenefit Start">
                <el-date-picker
                  @change="searchHandler"
                  placeholder="MyBenefit Start Month"
                  v-model="filter.myben_start_at"
                  size="small"
                  type="month"/>
              </el-form-item>
              <el-form-item label="MyBenefit End">
                <el-date-picker
                  v-model="filter.myben_end_at"
                  @change="searchHandler"
                  size="small"
                  placeholder="MyBenefit End Month"
                  type="month"/>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-button type="info" @click="page = 1; filter.page = 1; searchHandler()" size="small"><font-awesome-icon icon="search"/> Search</el-button>
        <el-button :disabled="!canCreateCompany" @click="handlerBtnCreate" type="primary" size="small"><font-awesome-icon icon="building"/> Add new company</el-button>
      </div>
      <div class="table-responsive" v-loading="loading.data">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>MyBenefit Package</th>
              <th>MyBenefit Effective Date</th>
              <th>Wallet Status</th>
              <!-- <th>Wallet Balance</th> -->
              <th>Created Date</th>
              <th>PIC Email</th>
              <th>PIC Phone</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in companies.rows" :key="item.id">
              <td>{{ item?.company_name }}</td>
              <td>{{ item?.company_email || '-' }}</td>
              <td>{{ item?.company_phone || '-' }}</td>
              <td>
                <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>
                <!-- <span class="tag" :class="item.mb_package_color">
                  {{ item.mb_package }}
                </span> -->
                <div class="row" v-if="item.mybenefit_package">
                  <div class="col-lg-3">
                    <img :src="item.mybenefit_package.package_logo"/>
                  </div>
                  <div class="col">
                    <div class="font-weight-medium">
                      {{ item.mb_package }}
                    </div>
                  </div>
                </div>
                <span v-else>-</span>
              </td>
              <td>{{ item.myben_period }}</td>
              <td>
                <span class="tag" :class="item.mbayar_status_color">{{ item.mbayar_status }}</span>
              </td>
              <td>{{ item.created_at_str }}</td>
              <!-- <td>{{ getLastCompanyInternalUser(item.company_internal_users)?.email || '-' }}</td>
              <td>{{ getLastCompanyInternalUser(item.company_internal_users)?.phone_number || '-' }}</td> -->
              <td v-if="item.company_internal_users && item.company_internal_users.length">
                {{ getLastCompanyInternalUser(item.company_internal_users)?.email || '-' }}
              </td>
              <td v-else>
                {{ item.pic_email || '-' }}
              </td>
              <td v-if="item.company_internal_users && item.company_internal_users.length">
                {{ getLastCompanyInternalUser(item.company_internal_users)?.phone_number || '-' }}
              </td>
              <td v-else>
                {{ item.pic_phone_number || '-' }}
              </td>
              <td>
                <el-dropdown @command="handleCommand($event, item)" size="mini" type="info">
                  <el-button size="mini" type="info">
                    Action&nbsp;<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" class="px-2 py-2">
                    <el-dropdown-item class="py-1" command="handlerBtnEditCompany">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="edit"/></span>
                        <span>Edit Company Data</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="item.mbayar_enabled || item.status != 'active'" class="py-1" command="handlerBtnActivateMbayar">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="wallet"/></span>
                        <span>Activate Wallet</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item class="py-1" command="handlerBtnMyBenefit">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="gift"/></span>
                        <span>My Benefit</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item class="py-1" command="handlerUnsubscribeMyBenefit" :disabled="item.mybenefit_package_id === 0 || item.mybenefit_package_id === null">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="gift"/></span>
                        <span>Unsubscribe My Benefit</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="item.status == 'waiting_approval'" class="py-1" command="handlerBtnPrivateUser">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="mobile-alt"/></span>
                        <span>Private Users</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="item.status == 'waiting_approval'" class="py-1" command="handlerBtnEwa">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="money-bill-wave"/></span>
                        <span>EWA</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="!item.mbayar_enabled" class="py-1" command="handlerBtnBalanceHistories">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="history"/></span>
                        <span>Wallet Histories</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="!item.mbayar_enabled" class="py-1" command="handlerBtnCashout">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="cash-register"/></span>
                        <span>Withdrawal</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="(item.status != 'waiting_approval' || !canApproveCompany)" class="py-1" command="handlerBtnApproveCompany">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="check"/></span>
                        <span>Approve</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item class="py-1" command="handlerBtnDeleteCompany">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="edit"/></span>
                        <span>Delete Company</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty v-if="companies.count == 0" description="No data found" :image="emptyStateImage"></el-empty>
      </div>
      <pagination
        :page="filter.page"
        :per_page="filter.per_page"
        :list="companies.rows"
        :total_rows="companies.count"
        @pageChange="pageChangeHandler"/>
      <create-company
        :is-shown="showModalCreateCompany"
        @onHide="showModalCreateCompany = false"
        :selected-company="selected_company"
        :modal-type="modal_type"
        @afterSubmit="loadCompanies"/>
      <activate-mbayar
        :is-shown="showModalActivateMbayar"
        :selected-company="selected_company"
        @onHide="showModalActivateMbayar = false; loadCompanies()" />
      <my-benefit
        :is-shown="showModalMyBenefit"
        :selected-company="selected_company"
        @onHide="showModalMyBenefit = false; loadCompanies()" />
      <how-topup
        :is-show="showModalHowTopup"
        :phoneNumber="topupPhoneNumber"
        @onHide="showModalHowTopup = false"/>
      <balance-histories
        :is-shown="showModalBalanceHistories"
        :selected-company="selected_company"
        @onHide="showModalBalanceHistories = false"/>
      <cashout
        :is-shown="showModalCashout"
        :selected-company="selected_company"
        @onHide="showModalCashout = false" />
    </div>
  </div>
</template>
<script>
/* eslint-disable radix */
import moment from 'moment';
import {
  isEmpty, isEqual, clone,
} from 'lodash';
import {
  GET_COMPANIES, CHECK_MBAYAR_BALANCE, APPROVE_CREATE_COMPANY, DELETE_COMPANIES
} from '@/store/modules/companies';
import {
  GET_MYBENEFIT, ANALYTIC_MYBENEFIT_COMPANY, UNASSIGN_PACKAGE
} from '@/store/modules/mybenefit';
import emptyStateImage from '@/assets/images/empty-state.png';
import CreateCompany from './companies/CreateCompany.vue';
import ActivateMbayar from './companies/ActivateMbayar.vue';
import MyBenefit from './companies/MyBenefit.vue';
import HowTopup from './disbursement/HowTopup.vue';
import BalanceHistories from './companies/BalanceHistories.vue';
import Cashout from './companies/Cashout.vue';
import popupErrorMessages from '../../library/popup-error-messages';
import Pagination from '../components/Pagination.vue';
import AppUserAnalytic from './app_user/Analytic.vue';
import MyBenefitCompanyAnalytic from './mybenefit/CompanyAnalytic.vue';

export default {
  components: {
    CreateCompany,
    ActivateMbayar,
    MyBenefit,
    HowTopup,
    BalanceHistories,
    Cashout,
    Pagination,
    AppUserAnalytic,
    MyBenefitCompanyAnalytic,
  },
  name: 'Companies',
  metaInfo: {
    title: 'Companies',
  },
  data() {
    return {
      emptyStateImage,
      showModalCreateCompany: false,
      showModalActivateMbayar: false,
      showModalMyBenefit: false,
      showModalHowTopup: false,
      showModalBalanceHistories: false,
      showModalCashout: false,
      topupPhoneNumber: '',
      loading: {
        data: false,
      },
      filter: {
        search_keyword: '',
        search_by: 'company_name',
        mybenefit_package: '',
        status_filter: '',
        page: 1,
        per_page: 10,
      },
      search_options: [
        { value: 'company_name', text: 'Company Name' },
        { value: 'company_email', text: 'Company Email' },
        { value: 'company_phone', text: 'Company Phone' },
      ],
      status_options: [
        { value: '', text: 'All' },
        { value: 'active', text: 'Active' },
        { value: 'waiting_approval', text: 'Waiting Approval' },
      ],
      companies: {
        rows: [],
        count: 0,
      },
      timeoutSearchCompany: null,
      page: 1,
      per_page: 10,
      selected_company: {},
      modal_type: 'add',
      package_list: [],
      crm_role_id: process.env.VUE_APP_CRM_ROLE_ID,
      current_active_tab: 0,
      temp_query: {},
      myben_analytic: [],
    };
  },
  async mounted() {
    if (!isEmpty(this.$route.query)) {
      this.filter = clone(this.$route.query);
      this.filter.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      this.filter.per_page = this.$route.query.per_page ? parseInt(this.$route.query.per_page) : 10;
    }
    const loader = this.$loading.show();
    await this.loadCompanies();
    this.loadMyBenefitList();
    this.myBenefitAnalytic();
    loader.hide();
  },
  computed: {
    showingFrom() {
      return this.companies.count ? ((this.filter.page - 1) * this.filter.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.companies.count) {
        if (this.companies.rows.length < this.filter.per_page) {
          return this.companies.count;
        }
        return this.filter.page * this.filter.per_page;
      }
      return 0;
    },
    currentMyProfile() {
      return this.$store.getters.currentMyProfile;
    },
    // canCreateCompany() {
    //   return this.currentRole.page_name === '*' || this.currentMyProfile.is_maker;
    // },
    canCreateCompany() {
      return (
        this.currentRole.page_name === '*' ||
        this.currentRole.page_name === 'companies' ||
        this.currentMyProfile.is_maker
      );
    },
    currentRole() {
      return this.$store.getters.current_role;
    },
    // canApproveCompany() {
    //   return this.currentRole.page_name === '*' || this.currentMyProfile.is_checker;
    // },
    canApproveCompany() {
      return (
        this.currentRole.page_name === '*' ||
        this.currentRole.page_name === 'companies' ||
        this.currentMyProfile.is_checker
      )
    },
  },
  methods: {
    handlerBtnCreate() {
      this.showModalCreateCompany = true;
      this.modal_type = 'add';
      this.selected_company = {};
    },
    async loadMyBenefitList() {
      this.loading.package_list = true;
      await this.$store.dispatch(GET_MYBENEFIT).then((response) => {
        this.package_list = response.rows;
      });
      this.loading.package_list = false;
    },
    pageChangeHandler(page) {
      this.filter.page = page;
      // this.loadCompanies();
      this.updateURLQuery();
    },
    updateURLQuery() {
      if (!isEqual(this.$route.query, this.filter)) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            page: this.filter.page,
            ...this.filter,
          },
        }).catch(() => {});
      }
    },
    async loadCompanies() {
      this.loading.data = true;
      this.updateURLQuery();
      await this.$store.dispatch(GET_COMPANIES, {
        page: this.filter.page,
        ...this.filter,
      });
      this.loading.data = false;
      this.companies = this.$store.getters.companies;
      this.companies.rows.forEach((v, index) => {
        if (v.mbayar_status.toLowerCase() === 'active') {
          this.checkBalance(index);
        }
      });
    },
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    doShowModalCreateCompany() {
      this.modal_type = 'add';
      this.showModalCreateCompany = true;
    },
    closeModalCreateCompany() {
      this.showModalCreateCompany = false;
    },
    handlerBtnEditCompany(item) {
      this.selected_company = item;
      this.modal_type = 'edit';
      this.showModalCreateCompany = true;
    },
    handlerBtnActivateMbayar(item) {
      this.selected_company = item;
      this.showModalActivateMbayar = true;
    },
    handlerBtnMyBenefit(item) {
      this.selected_company = item;
      this.showModalMyBenefit = true;
    },
    handlerUnsubscribeMyBenefit(item) {
      // console.log("company id", item);
      this.$confirm('Are you sure want to unsubscribe mybenefit for this company?', 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Yes',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            await this.$store.dispatch(UNASSIGN_PACKAGE, {
              id: item.company_id
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'MyBenefit removed',
              });
            }).catch((err) => {
              popupErrorMessages(err?.response?.data);
            })
          }
          done();
          this.loadCompanies();
        }
      }).catch(() => {});
      // console.log("are you sure wanna unsubscribe mybenefit for this company?");
    },
    handlerBtnPrivateUser(item) {
      console.log("company_id:", item.company_id);
        if (item.ewa_tier !== null && item.ewa_tier !== undefined) {
          try {
            localStorage.setItem('ewa_tier', JSON.stringify(item.ewa_tier));
          } catch (e) {
            console.error("Error saving ewa_tier to local storage:", e);
          }
        } else {
          localStorage.removeItem('ewa_tier');
        }
      this.$router.push({
        name: 'Companies Private User',
        params: {
          id: item.company_id,
        },
      });
    },
    handlerBtnEwa(item) {
      this.$router.push({
        name: 'Companies EWA',
        params: {
          id: item.company_id,
        },
      });
    },
    async checkBalance(index) {
      this.companies.rows[index].balance_loading = true;
      await this.$store.dispatch(CHECK_MBAYAR_BALANCE, {
        id: this.companies.rows[index].company_id,
      }).then(({ data }) => {
        this.companies.rows[index].wallet_balance = data.balance.balance;
        this.companies.rows[index].wallet_balance_str = `Rp.${data.balance.balance.toLocaleString()}`;
      }).catch(() => {});
      this.companies.rows[index].balance_loading = false;
    },
    handlerHowTopup(item) {
      this.showModalHowTopup = true;
      this.topupPhoneNumber = item.company_phone;
    },
    handlerBtnBalanceHistories(item) {
      this.selected_company = item;
      this.showModalBalanceHistories = true;
    },
    handlerBtnCashout(item) {
      this.selected_company = item;
      this.showModalCashout = true;
    },
    searchHandler() {
      this.filter.page = 1;
      if (this.filter.myben_start_at) {
        this.filter.myben_start_at = moment(this.filter.myben_start_at).format('YYYY-MM-DD');
      }
      if (this.filter.myben_end_at) {
        this.filter.myben_end_at = moment(this.filter.myben_end_at).format('YYYY-MM-DD');
      }
      this.loadCompanies();
    },
    handlerBtnApproveCompany(item) {
      this.$confirm('Are you sure want to approve this company?', 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Yes',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            // this.doCreatePromo();
            instance.confirmButtonLoading = true;
            try {
              await this.$store.dispatch(APPROVE_CREATE_COMPANY, {
                id: item.company_id,
                data: {},
              }).then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Approval success',
                });
                this.loadCompanies();
                done();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
              instance.confirmButtonLoading = false;
            } catch (err) {
              console.log(err);
            }
            instance.confirmButtonLoading = false;
          }
          done();
        },
      });
    },
    handlerBtnDeleteCompany(item) {
      this.$confirm('Are you sure want to delete this company?', 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Yes',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            try {
              await this.$store.dispatch(DELETE_COMPANIES, {
                id: item.company_id,
                data: {},
              }).then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Company Deleted',
                });
                this.loadCompanies();
                done();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
              instance.confirmButtonLoading = false;
            } catch (err) {
              console.log(err);
            }
            instance.confirmButtonLoading = false;
          }
          done();
        },
      });
    },
    handleCommand(fn, item) {
      this[fn](item);
    },
    showLoader() {
      this.$parent.showLoader();
    },
    hideLoader() {
      this.$parent.hideLoader();
    },
    handlerTabChange(value) {
      if (value === 0) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
          },
        }).catch(() => {});
        this.loadCompanies();
      } else if (value === 1) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
          },
        }).catch(() => {});
        this.getMyBenefitRequest();
      }
    },
    async getMyBenefitRequest() {
      this.$refs.mybenefit.getList();
    },
    async myBenefitAnalytic() {
      let { rows } = await this.$store.dispatch(ANALYTIC_MYBENEFIT_COMPANY);
      rows = rows.map((v) => ({ count: v.count, ...v.mybenefit_package }));
      this.myben_analytic = rows;
    },
    getLastCompanyInternalUser(users) {
      if (!users || users.length === 0) return null;
      return users[users.length - 1];
    }
  },
};
</script>
<style>
.tag-gold {
  background-color: rgba(248, 173, 1, 0.8);
  color: #fff;
  font-weight: 600;
}
.tag-bronze {
  background-color: rgba(165, 127, 75, 0.8);
  color: #fff;
  font-weight: 600;
}
.tag-trial {
  background-color: rgba(144, 70, 251, 0.6);
  color: #fff;
  font-weight: 600;
}
.tag-silver {
  background-color: rgba(169, 169, 169, 0.8);
  color: #fff;
  font-weight: 600;
}
</style>
