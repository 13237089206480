<template>
  <b-modal size="lg" v-model="show" title="Cashout" hide-footer @hide="onHide">
    <el-steps :active="current_step" finish-status="success" simple="">
      <el-step title="Form" icon="el-icon-set-up"></el-step>
      <el-step title="Confirm" icon="el-icon-postcard"></el-step>
      <el-step title="OTP" icon="el-icon-phone"></el-step>
      <el-step title="Completed" icon="el-icon-check"></el-step>
    </el-steps>
    <template v-if="current_step == 0">
      <div class="mt-2">
        <p>You will submit a cashout request. This process will take about 2-3 days after request has been submitted.</p>
      </div>
      <b-form-group label="Bank">
        <el-select filterable v-model="v$.form.bank_id.$model" placeholder="Select bank" class="w-full">
          <el-option v-for="(item) in bank_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <b-form-invalid-feedback :state="!v$.form.bank_id.$invalid" v-if="v$.form.bank_id.$errors[0]">
          <span> {{ v$.form.bank_id.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Account Number">
        <b-input placeholder="Input account number" v-model="v$.form.account_number.$model"/>
        <b-form-invalid-feedback :state="!v$.form.account_number.$invalid" v-if="v$.form.account_number.$errors[0]">
          <span> {{ v$.form.account_number.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Cashout Amount">
        <b-input placeholder="Input cashout amount" v-model="v$.form.cashout_amount.$model"/>
        <b-form-invalid-feedback :state="!v$.form.cashout_amount.$invalid" v-if="v$.form.cashout_amount.$errors[0]">
          <span> {{ v$.form.cashout_amount.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
        <small>Balance: {{ selectedCompany.wallet_balance_str }}</small>
      </b-form-group>
      <div class="d-flex flex-row float-right">
        <el-button @click="onSubmit" :loading="loading.step1" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
        <el-button @click="onClose" size="small" class="mr-2">Cancel</el-button>
      </div>
    </template>
    <!-- Step 2 -->
    <template v-if="current_step == 1">
      <el-descriptions class="margin-top" title="Cashout Information" :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            Account Name
          </template>
          {{ inquiry_detail.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-bank-card"></i>
            Account Number
          </template>
          {{ inquiry_detail.no_rek }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            Bank
          </template>
          {{ inquiry_detail.bank_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-money"></i>
            Cashout Amount
          </template>
          Rp.{{ inquiry_detail.amount.toLocaleString() }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <font-awesome-icon icon="money-bill-wave"/>
            Admin Fee
          </template>
          Rp.{{ inquiry_detail.fee.toLocaleString() }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="d-flex flex-row float-right mt-2">
        <el-button @click="onSubmitStep2" :loading="loading.step2" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
        <el-button @click="onClose" size="small" class="mr-2">Cancel</el-button>
      </div>
    </template>
    <template v-if="current_step == 2">
      <div class="mt-2 mb-2">
        <p>OTP has been sent to <strong>{{ phone_number }}</strong>. Please check your inbox message. If you haven't received the message, please kindly wait.</p>
      </div>
      <b-form-group label="OTP">
        <b-input-group>
          <template #append>
            <b-input-group-text class="cursor-pointer" @click="submitResendOtp">
              <!-- <span v-if="input_type.new_password === 'text'"><font-awesome-icon icon="eye"/></span> -->
              <span v-if="countDown == 0">Resend OTP</span>
              <span v-else>00:{{ countDown.toString().padStart(2, '0') }}</span>
            </b-input-group-text>
          </template>
          <b-input placeholder="Please input OTP" v-model="v$.form2.otp.$model"/>
        </b-input-group>
        <b-form-invalid-feedback :state="!v$.form2.$invalid" v-if="v$.form2.otp.$errors[0]">
          <span> {{ v$.form2.otp.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex flex-row float-right">
        <el-button @click="onSubmitStep3" :loading="loading.step3" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
        <el-button @click="onClose" size="small" class="mr-2">Cancel</el-button>
      </div>
    </template>
    <template v-if="current_step == 3">
      <div class="el-result">
        <div class="el-result__icon">
          <font-awesome-icon icon="check-circle" class="w-5 h-5 text-success"></font-awesome-icon>
        </div>
        <div class="el-result__title">
          <p>Success!</p>
        </div>
        <div class="el-result__subtitle">
          <p>Cashout Request has successfully submitted. Please wait for 2-3 days for us to process your request. Thank you!</p>
        </div>
        <div class="el-result__extra">
          <el-button type="primary" @click="onClose" size="small" class="mr-2">Okay</el-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, helpers,
} from '@vuelidate/validators';
import {
  GET_BANK_LIST_CASHOUT, INQUIRY_CASHOUT,
  INQUIRY_OTP_CASHOUT, PROCESS_CASHOUT,
} from '@/store/modules/companies';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'CashoutCompanies',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    selectedCompany: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        account_number: { required, numeric },
        cashout_amount: {
          required,
          numeric,
          maxValue: helpers.withMessage(() => 'Max amount is your wallet balance or 50.000.000', (value) => value <= 50000000 && value <= this.selectedCompany.wallet_balance),
        },
        bank_id: { required },
      },
      form2: {
        otp: { required },
      },
    };
  },
  data() {
    return {
      show: false,
      otp: '',
      phone_number: '',
      current_step: 0,
      loading: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
      },
      form: {
        bank_id: '',
        account_number: '',
        cashout_amount: '',
      },
      form2: {
        otp: '',
      },
      countDown: 0,
      bank_list: [],
      inquiry_detail: {
        no_rek: '',
        fee: 0,
        amount: 0,
        bank_id: '',
        bank_name: '',
        name: '',
        inq_id: '',
      },
      otp_token: '',
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onHide() {
      this.$emit('onHide');
    },
    async onSubmit() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.loading.step1 = true;
      await this.$store.dispatch(INQUIRY_CASHOUT, {
        company_id: this.selectedCompany.company_id,
        bank_id: this.form.bank_id,
        account_id: this.form.account_number,
      }).then((data) => {
        this.current_step += 1;
        this.inquiry_detail = data;
        this.inquiry_detail.bank_name = this.bank_list.find((v) => v.id === this.form.bank_id).name;
        /* eslint-disable radix */
        this.inquiry_detail.amount = parseInt(this.form.cashout_amount);
      }).catch((err) => {
        console.log(err);
        popupErrorMessages(err.response.data);
      });
      this.loading.step1 = false;
    },
    onClose() {
      this.form = {
        account_number: '',
        cashout_amount: 0,
      };
      this.current_step = 0;
      this.onHide();
    },
    async submitResendOtp() {
      this.loading.step2 = true;
      await this.$store.dispatch(INQUIRY_OTP_CASHOUT, {
        company_id: this.selectedCompany.company_id,
      }).then((data) => {
        this.otp_token = data.token;
        this.phone_number = data.phone;
      }).catch((err) => {
        console.log(err);
        popupErrorMessages(err.response.data);
      });
      this.loading.step2 = false;
    },
    async onSubmitStep2() {
      await this.submitResendOtp();
      this.current_step += 1;
    },
    async onSubmitStep3() {
      this.v$.form2.$touch();
      if (this.v$.form2.$error) return;
      this.loading.step3 = true;
      await this.$store.dispatch(PROCESS_CASHOUT, {
        otp_token: this.otp_token,
        otp: this.form2.otp,
        company_id: this.selectedCompany.company_id,
        bank_id: this.form.bank_id,
        account_id: this.form.account_number,
        amount: this.form.cashout_amount,
        inq_id: this.inquiry_detail.inq_id,
      }).then(() => {
        this.current_step += 1;
      }).catch((err) => {
        console.log(err);
        popupErrorMessages(err.response.data);
      });
      this.loading.step3 = false;
    },
    async loadBankList() {
      await this.$store.dispatch(GET_BANK_LIST_CASHOUT, {
        limit: 200,
      }).then((data) => {
        this.bank_list = data.rows;
      }).catch((err) => {
        console.log(err);
        popupErrorMessages(err.response.data);
      });
    },
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      if (this.show) {
        this.loadBankList();
      }
    },
  },
};
</script>