<template>
  <b-modal size="lg" v-model="show" title="Activate MBayar" hide-footer @hide="onHide">
    <el-steps :active="current_step" finish-status="success" simple="">
      <el-step title="Confirmation" icon="el-icon-warning-outline"></el-step>
      <el-step title="OTP" icon="el-icon-phone-outline"></el-step>
      <el-step title="Completed" icon="el-icon-check"></el-step>
    </el-steps>
    <!-- Step 1 -->
    <template v-if="current_step == 0">
      <div class="mt-2">
        <p>You will register this company to MBayar as a Merchant. You can not change any data with this dashboard after this process has been submitted. Any kind of change would be process manually.</p>
      </div>
      <b-form-group label="Company Name">
        <b-input placeholder="Input new company name" v-model="v$.form.company_name.$model"/>
        <b-form-invalid-feedback :state="!v$.form.company_name.$invalid" v-if="v$.form.company_name.$errors[0]">
          <span> {{ v$.form.company_name.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Company Email">
        <b-input placeholder="Input new company email" v-model="v$.form.company_email.$model"/>
        <b-form-invalid-feedback :state="!v$.form.company_email.$invalid" v-if="v$.form.company_email.$errors[0]">
          <span> {{ v$.form.company_email.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Company Phone">
        <b-input placeholder="Input new company phone" v-model="v$.form.company_phone.$model"/>
        <small class="text-warning" style="line-height: 0px; font-weight: 800;">This phone number will receive OTP. Make sure the phone number is active before continue to next process</small>
        <b-form-invalid-feedback :state="!v$.form.company_phone.$invalid" v-if="v$.form.company_phone.$errors[0]">
          <span> {{ v$.form.company_phone.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex flex-row float-right">
        <el-button @click="onSubmit" :loading="loading.step1" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
        <el-button @click="onClose" size="small" class="mr-2">Cancel</el-button>
      </div>
    </template>
    <!-- Step 2 -->
    <template v-if="current_step == 1">
      <div class="mt-2">
        <p>OTP has been sent to <strong>{{ form.company_phone }}</strong>. Please check your inbox message. If you haven't received the message, please kindly wait or check your phone number balance.</p>
      </div>
      <b-form-group label="OTP">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <span>{{ otp_prefix }}-</span>
            </b-input-group-text>
          </template>
          <template #append>
            <b-input-group-text class="cursor-pointer" @click="submitResendOtp">
              <!-- <span v-if="input_type.new_password === 'text'"><font-awesome-icon icon="eye"/></span> -->
              <span v-if="countDown == 0">Resend OTP</span>
              <span v-else>00:{{ countDown.toString().padStart(2, '0') }}</span>
            </b-input-group-text>
          </template>
          <b-input placeholder="Please input OTP" v-model="v$.form2.otp.$model"/>
        </b-input-group>
        <b-form-invalid-feedback :state="!v$.form.form2.$invalid" v-if="v$.form2.otp.$errors[0]">
          <span> {{ v$.form2.otp.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex flex-row float-right">
        <el-button @click="onSubmit" :loading="loading.step2" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
        <el-button @click="onClose" size="small" class="mr-2">Cancel</el-button>
      </div>
    </template>
    <!-- Step 3 -->
    <template v-if="current_step == 2">
      <div class="el-result">
        <div class="el-result__icon">
          <font-awesome-icon icon="check-circle" class="w-5 h-5 text-success"></font-awesome-icon>
        </div>
        <div class="el-result__title">
          <p>Success!</p>
        </div>
        <div class="el-result__subtitle">
          <p>This company has been successfully registered as MBayar merchant. Congratulations!</p>
        </div>
        <div class="el-result__extra">
          <el-button type="primary" @click="onClose" size="small" class="mr-2">Okay</el-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  required, email, minLength, numeric,
} from '@vuelidate/validators';
import popupErrorMessages from '@/library/popup-error-messages';
import { REGISTER_MBAYAR, VALIDATE_OTP_MBAYAR } from '@/store/modules/companies';

export default {
  name: 'ActivateMbayar',
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        company_name: { required },
        company_phone: {
          required, numeric, minLength: minLength(8),
        },
        company_email: { required, email },
      },
      form2: {
        otp: { required },
      },
    };
  },
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    selectedCompany: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      show: false,
      current_step: 0,
      form: {
        company_name: '',
        company_email: '',
        company_phone: '',
      },
      form2: {
        otp: '',
      },
      loading: {
        step1: false,
        step2: false,
        step3: false,
      },
      otp_action: '',
      otp_prefix: '',
      countDown: 0,
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onHide() {
      this.show = false;
      this.resetForm();
      this.$emit('onHide');
    },
    async onSubmit() {
      const step = this.current_step;
      if (step === 0) {
        this.otp_action = '';
        await this.submitRequestOTP(() => {
          console.log('done, next to step 2');
          this.current_step += 1;
        });
      } else if (step === 1) {
        await this.validateOtp(() => {
          this.current_step += 1;
          // this.$parent.loadCompanies();
        });
      }
      // this.current_step += 1;
    },
    submitRequestOTP(callback) {
      const phone = this.form.company_phone;
      this.$confirm(`An OTP will send to ${phone}, make sure the number is active an have enough balance to receive OTP from MBayar.`, 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Continue',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            // this.doCreatePromo();
            instance.confirmButtonLoading = true;
            this.loading.step1 = true;
            try {
              await this.$store.dispatch(REGISTER_MBAYAR, {
                action: this.otp_action,
                data: {
                  phone: this.form.company_phone,
                  name: this.form.company_name,
                  email: this.form.company_email,
                  phone_number: this.form.company_phone,
                },
              }).then((response) => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: `OTP has been sent to ${phone}`,
                });
                console.log('response', response);
                this.otp_prefix = response.data.otp.tokenPrefix || response.data.otp.token_prefix;
                this.countDown = 30;
                this.countDownTimer();
                callback();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
              instance.confirmButtonLoading = false;
              this.loading.step1 = false;
            } catch (err) {
              console.log(err);
            }
            this.loading.step1 = false;
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).catch(() => {
        this.otp_action = '';
      });
    },
    submitResendOtp() {
      if (this.countDown > 0) return;
      this.otp_action = 'resend_otp';
      this.submitRequestOTP(() => {
        // done
      });
    },
    async validateOtp(callback) {
      try {
        this.v$.form2.$touch();
        if (this.v$.form2.$error) return;
        const loader = this.$loading.show();
        this.loading.step2 = true;
        await this.$store.dispatch(VALIDATE_OTP_MBAYAR, {
          token: [this.otp_prefix, this.form2.otp].join(''),
          company_id: this.selectedCompany.company_id,
        }).then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Successfully validate OTP',
          });
          callback();
        }).catch((err) => {
          if (err.response) popupErrorMessages(err.response.data);
        });
        loader.hide();
        this.loading.step2 = false;
      } catch (error) {
        this.loading.step2 = false;
      }
    },
    resetForm() {
      this.current_step = 0;
      this.form = {
        company_name: '',
        company_email: '',
        company_phone: '',
      };
      this.form2 = {
        otp: '',
      };
    },
    onClose() {
      this.show = false;
      this.resetForm();
      this.$emit('onClose');
    },
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      if (this.show) {
        this.form = {
          company_name: this.selectedCompany.company_name,
          company_email: this.selectedCompany.company_email,
          company_phone: this.selectedCompany.company_phone,
        };
        this.v$.form.$touch();
      }
    },
  },
};
</script>
<style>
.el-result {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  padding: 40px 30px;
}
</style>
