<template>
  <div class="row row-deck row-cards mb-3">
    <div class="col-sm-6 col-lg-3">
      <div class="card">
        <div class="card-body" v-loading="isLoading">
          <div class="d-flex align-items-center">
            <div class="subheader">
              <span>Today New {{ isPrivate ? 'Private ' : ''}}User</span>
            </div>
            <div class="ms-auto lh-1 subheader" :class="{ 'text-red': today_register < yesterday_register, 'text-green': today_register > yesterday_register }">
              <font-awesome-icon :icon="icon_today_register"/> {{ today_new_progress }}%
            </div>
          </div>
          <div class="h1 mb-3"><b>{{today_register}}</b></div>
          <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="register_chart.options" :series="register_chart.series"/>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <div class="card">
        <div class="card-body" v-loading="isLoading">
          <div class="d-flex align-items-center">
            <div class="subheader">
              <span>Yesterday New {{ isPrivate ? 'Private ' : ''}}User</span>
            </div>
          </div>
          <div class="h1 mb-3"><b>{{yesterday_register}}</b></div>
          <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="register_chart.options" :series="register_chart.series"/>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <div class="card">
        <div class="card-body" v-loading="isLoading">
          <div class="d-flex align-items-center">
            <div class="subheader">
              <span>Today Login {{ isPrivate ? 'Private ' : ''}}User</span>
            </div>
            <div class="ms-auto lh-1 subheader" :class="{ 'text-red': today_login < yesterday_login, 'text-green': today_login > yesterday_login }">
              <font-awesome-icon :icon="icon_today_login"/> {{ today_login_progress }}%
            </div>
          </div>
          <div class="h1 mb-3"><b>{{today_login}}</b></div>
          <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="login_chart.options" :series="login_chart.series"/>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <div class="card">
        <div class="card-body" v-loading="isLoading">
          <div class="d-flex align-items-center">
            <div class="subheader">
              <span>Yesterday Login {{ isPrivate ? 'Private ' : ''}}User</span>
            </div>
          </div>
          <div class="h1 mb-3"><b>{{yesterday_login}}</b></div>
          <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="login_chart.options" :series="login_chart.series"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { orderBy } from 'lodash';
import {
  ANALYTIC_APPUSERS,
} from '@/store/modules/app_user';

export default {
  name: 'AppUserAnalytic',
  props: {
    isPrivate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      today: moment().format('YYYY-MM-DD'),
      yest: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      analytic: {},
      today_register: 0,
      today_login: 0,
      yesterday_register: 0,
      yesterday_login: 0,
      register_chart: {
        options: {
          chart: {
            type: 'area',
            fontFamily: 'inherit',
            height: 40.0,
            sparkline: {
              enabled: true,
            },
            animations: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: 0.16,
            type: 'solid',
          },
          stroke: {
            width: 2,
            lineCap: 'round',
            curve: 'smooth',
          },
          xaxis: {
            labels: {
              padding: 0,
            },
            tooltip: {
              enabled: false,
            },
            axisBorder: {
              show: false,
            },
            type: 'datetime',
            categories: [],
          },
          yaxis: {
            labels: {
              padding: 4,
            },
          },
          labels: [],
          legend: {
            show: false,
          },
        },
        series: [],
      },
      login_chart: {
        options: {
          chart: {
            type: 'area',
            fontFamily: 'inherit',
            height: 40.0,
            sparkline: {
              enabled: true,
            },
            animations: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: 0.16,
            type: 'solid',
          },
          stroke: {
            width: 2,
            lineCap: 'round',
            curve: 'smooth',
          },
          xaxis: {
            labels: {
              padding: 0,
            },
            tooltip: {
              enabled: false,
            },
            axisBorder: {
              show: false,
            },
            type: 'datetime',
            categories: [],
          },
          yaxis: {
            labels: {
              padding: 4,
            },
          },
          labels: [],
          legend: {
            show: false,
          },
        },
        series: [],
      },
    };
  },
  mounted() {
    this.getAnalytic();
  },
  computed: {
    icon_today_register() {
      if (this.today_register < this.yesterday_register) {
        return 'chevron-down';
      }
      if (this.today_register > this.yesterday_register) {
        return 'chevron-up';
      }
      return 'minus';
    },
    icon_yesterday_register() {
      if (this.today_register > this.yesterday_register) {
        return 'chevron-down';
      }
      if (this.today_register < this.yesterday_register) {
        return 'chevron-up';
      }
      return 'minus';
    },
    icon_today_login() {
      if (this.today_login < this.yesterday_login) {
        return 'chevron-down';
      }
      if (this.today_login > this.yesterday_login) {
        return 'chevron-up';
      }
      return 'minus';
    },
    icon_yesterday_login() {
      if (this.today_login > this.yesterday_login) {
        return 'chevron-down';
      }
      if (this.today_login < this.yesterday_login) {
        return 'chevron-up';
      }
      return 'minus';
    },
    today_new_progress() {
      if (this.yesterday_register > 0) {
        return (((this.today_register - this.yesterday_register) / this.yesterday_register).toFixed(3) * 100).toFixed(2);
      }
      if (this.yesterday_register === this.today_register) {
        return 0;
      }
      return 100;
    },
    today_login_progress() {
      if (this.yesterday_login > 0) {
        return (((this.today_login - this.yesterday_login) / this.yesterday_login).toFixed(3) * 100).toFixed(2);
      }
      if (this.yesterday_login === this.today_login) {
        return 0;
      }
      return 100;
    },
  },
  methods: {
    async getAnalytic() {
      this.isLoading = true;
      await this.$store.dispatch(ANALYTIC_APPUSERS, {
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        is_private: this.isPrivate ? 1 : 0,
      }).catch(() => {});
      this.analytic = this.$store.getters.analytic_appuser;
      this.analytic.login_daily = this.analytic.login_daily.filter((v) => v && v.login_date);

      this.today_register = this.analytic.register_daily.find((v) => v.register_date === this.today)?.count || 0;
      this.today_login = this.analytic.login_daily.find((v) => v.login_date === this.today)?.count || 0;
      this.yesterday_register = this.analytic.register_daily.find((v) => v.register_date === this.yest)?.count || 0;
      this.yesterday_login = this.analytic.login_daily.find((v) => v.login_date === this.yest)?.count || 0;
      const obj = orderBy(this.analytic.register_daily, 'register_date').reverse().splice(0, 30).reverse();
      this.register_chart.series = [{
        name: 'Daily New User',
        data: obj.map((v) => v.count),
      }];
      this.register_chart.options.labels = obj.map((v) => v.register_date);
      this.register_chart.options.xaxis.categories = obj.map((v) => v.register_date);

      let obj_login = orderBy(this.analytic.login_daily, 'login_date');
      obj_login = obj_login.reverse().splice(0, 30).reverse();

      this.login_chart.series = [{
        name: 'Daily Login',
        data: obj_login.map((v) => v.count),
      }];
      this.login_chart.options.labels = obj_login.map((v) => v.login_date);
      this.login_chart.options.xaxis.categories = obj_login.map((v) => v.login_date);

      this.isLoading = false;
    },
  },
};
</script>