<template>
  <div class="row row-deck row-cards mb-3">
    <div class="col-sm-6 col-lg-3" v-for="(item, index) in analytic" :key="index">
      <div class="card card-sm">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3">
              <img :src="item.package_logo"/>
            </div>
            <div class="col">
              <div class="font-weight-medium">
                {{ item.count }} Subscriber
              </div>
              <div class="text-muted">
                {{ item.package_name }} Package
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MyBenefitCompanyAnalytic',
  props: {
    analytic: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>