<template>
  <b-modal size="md" v-model="show" :title="title" hide-footer @hide="onHide">
    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Package Name">
        <b-input
          v-model="v$.form.package_name.$model"
          placeholder="Input package name"
          type="text"/>
        <b-form-invalid-feedback :state="!v$.form.package_name.$invalid" v-if="v$.form.package_name.$errors[0]">
          <span>{{ v$.form.package_name.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Voucher">
        <el-select class="mb-2" :loading="loading.voucher" v-model="v$.form.benefit_voucher_id.$model" placeholder="Select or search voucher" style="width: 100%" filterable remote :remote-method="doSearchVoucher">
        </el-select>
        <b-form-invalid-feedback :state="!v$.form.benefit_voucher_id.$invalid" v-if="v$.form.benefit_voucher_id.$errors[0]">
          <span>{{ v$.form.benefit_voucher_id.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
    <div class="d-flex float-right">
      <el-button @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
      <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
    </div>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  required,
} from '@vuelidate/validators';
import {
  GET_MYBENEFIT_VOUCHER,
} from '@/store/modules/mybenefit';

export default {
  name: 'CreateBenefitPackage',
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        package_name: { required },
        package_color: { required },
        benefit_voucher_id: { required },
        benefit_promo_id: { required },
        benefit_insurance_id: { required },
      },
    };
  },
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'create',
    },
    selectedPackage: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    title() {
      return this.type === 'create' ? 'Create Package' : 'Edit Package';
    },
  },
  data() {
    return {
      show: false,
      loading: {
        submit: false,
        voucher: false,
      },
      form: {
        package_name: '',
        package_color: '',
        benefit_voucher_id: '',
        benefit_promo_id: '',
        benefit_insurance_id: '',
      },
    };
  },
  methods: {
    onHide() {
      this.$emit('onHide');
    },
    onSubmit() {},
    async loadMyBenefitProduct({ type = 'voucher', search_keyword = '' }) {
      this.loading[type] = true;
      await this.$store.dispatch(GET_MYBENEFIT_VOUCHER, {
        search_by: 'name',
        search_keyword,
      }).then(({ data }) => {
        console.log(data);
      }).catch((err) => console.log(err));
      this.loading[type] = false;
    },
    doSearchVoucher() {

    },
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      if (this.show) {
        this.loadMyBenefitProduct();
        this.v$.form.$touch();
      }
    },
  },
};
</script>